<template>
  <div>
    <h2 v-html="$t('search_document_title')"></h2>
    <!-- Kontext  -->
    <v-select
      id="search_document_context"
      v-model="SearchDocumentStore.documentTypeGroup"
      :label="$t('search_document_context')"
      :items="documentTypeGroups"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <!-- Absender -->
    <v-select
      id="document_senderName"
      v-model="SearchDocumentStore.legalEntityId"
      :label="$t('document_senderName')"
      :items="sender"
      :item-title="this.$getLangKey()"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Dateiname -->
    <v-text-field id="document_title" v-model="SearchDocumentStore.filename" :label="$t('document_title')" type="text" @blur="search" />
    <!-- Von -->
    <DbmDatePicker v-model="SearchDocumentStore.from" :dateLabel="$t('search_document_from')" :clearable="true" />
    <!-- Bis -->
    <DbmDatePicker v-model="SearchDocumentStore.until" :dateLabel="$t('search_document_until')" :clearable="true" />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useDocumentTypeGroupsStore } from '@/store/enums/DocumentTypeGroupsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchDocument',
  extends: baseSearchVue,
  data() {
    return {
      sender: [
        // eventuell anpassen
        { id: 1, nameDe: 'TSM', nameFr: 'TSM', nameIt: 'TSM' },
        {
          id: 38,
          nameDe: 'Suisselab',
          nameFr: 'Suisselab',
          nameIt: 'Suisselab'
        },
        { id: 62, nameDe: 'ZMP', nameFr: 'ZMP', nameIt: 'ZMP' },
        { id: 2, nameDe: 'BLW', nameFr: 'BLW', nameIt: 'BLW' }
      ]
    }
  },
  computed: {
    documentTypeGroups() {
      return useDocumentTypeGroupsStore().documentTypeGroups
    },
    SearchDocumentStore() {
      return this.$store.state.SearchDocumentStore
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    reset() {
      this.resetFields(this.SearchDocumentStore)
    }
  },
  mounted() {
    if (this.$privileges.has({ path: '/documents', permission: 'read' })) {
      useDocumentTypeGroupsStore().fetchDocumentTypeGroups()
    }
    this.initSearchStoreFromQuery(this.SearchDocumentStore, this.$route.query)
  }
})
</script>
