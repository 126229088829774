import { defineStore } from 'pinia'
export const useDocumentTypeGroupsStore = defineStore('DocumentTypeGroupsStore', {
  state: () => ({
    documentTypeGroups: [
      {
        nameDe: 'Zulagen',
        name: 'SUBSIDIES',
        nameFr: 'Suppl. lait commercialisé',
        nameIt: 'Suppl. latte commercializzato',
        id: 5
      },
      {
        nameDe: 'Downloads',
        name: 'DOWNLOADS',
        nameFr: 'Downloads',
        nameIt: 'Downloads',
        id: 7
      }
    ]
  }),
  actions: {
    // todo atm we show only 'Zulagen', enable 'get' if you need all groups
    // const result = await app.axios.get(apiURL + '/documentTypeGroups')
    // return result.data
    fetchDocumentTypeGroups(): void {
      return
    }
  }
})
